import { CountryCode, Rol } from './common';
import { ICoverage, IInspection } from './inspection';

export interface IClaimOffice {
  id?: number;
  name?: string;
  address?: string;
  schedule?: string;
}

export interface IClaimPaymentCheck {
  type: string;
  town?: string;
  urbanization?: string;
  detailedAddress?: string;
  postalCode?: string;
  office?: IClaimOffice;
}

export interface IClaimPaymentACH {
  claimentName?: string;
  nameBank?: string;
  routeNumber?: string;
  accountNumber?: string;
  office?: string;
  phone?: string;
  accountType?: string;
  isAuthorize?: boolean;
}

export interface IClaimPayment {
  type: string;
  enableACH: boolean;
  receiveCheck?: IClaimPaymentCheck;
  receiveACH?: IClaimPaymentACH;
  paymentDate?: Date;
  repairDate?: Date;
}

export interface IClaimStage {
  id: number;
  sortId: number;
  name: string;
  status: string;
  stageTitle: string;
  description: string;
  createdDate: string;
}

export interface IClaimSteps {
  id: number;
  title: number;
  order: string;
  status: string;
  stageTitle: string;
  sections: any;
  constraints: any;
}

export interface IClaimForm {
  steps: IClaimStage[];
  info: any;
  history: any;
  case: string;
  claim: string;
  cases: any;
}

export interface IClaimRequest {
  id?: number;
  uId?: string;
  description?: string;
  fileURL?: string;
  status?: string;
  createdDate?: string;
}

export interface IClaimVehicle {
  make?: string;
  model?: string;
  year?: number;
  color?: string;
  plate?: string;
  vin?: string;
}

export interface IClaimDriver {
  firstName?: string;
  lastName?: string;
}

export interface IClaimOwner {
  firstName?: string;
  lastName?: string;
  phone?: string;
  policyNumber?: string;
}

export interface IEventData {
  event: IInspection;
  qualitasCoverages: ICoverage[];
}

export interface IClaim {
  accountName?: string;
  accountReference?: string;
  claimId: number;
  eventId?: number;
  claimUId: string;
  claimNumber: string;
  eventRecord: string;
  owner?: IClaimOwner;
  driver?: IClaimDriver;
  vehicle?: IClaimVehicle;
  agreementType: string;
  stages: IClaimStage[];
  agreementSignature?: string;
  agreementAmount?: number;
  estimatedAmount?: number;
  deductibleAmount?: number;
  depreciationAmount?: number;
  agreementAmountDetailUrl?: string;
  estimatedRepairDate?: Date;
  payment?: IClaimPayment;
  request?: IClaimRequest[];
  title: string;
  formSectionId: number;
  isSelected?: boolean;
  hasError?: boolean;
  status?: string;
  id: number;
  steps: IClaimSteps[];
  workshop?: IWorshop;
  notifySubrogation?: boolean;
  requiresApproval?: boolean;
  closedWithoutPayment?: boolean;
  jsonFieldData?: IEventData;
  contactPhone: string;
}

export interface IRequestForm {
  claimForm: IClaimForm;
}

export interface IClaimFile {
  name?: string;
  url: string;
  extension?: string;
  expenseDocument?: string;
  cmtDocumentsUpload?: string;
  cmtDocumentType?: string;
  cmtDocumentUser?: string;
  cmtDocumentRole?: string;
  estimatedMitchellDocument?: string;
  type?: string;
}

export interface IClaimGallery {
  sectionName: string;
  files: IClaimFile[];
}

export interface IClaimBarCharts {
  name: string;
  days: number;
}

export interface IClaimPercentage {
  number: number;
  percentage: number;
  defaultTitle: string;
  name: string;
}

export interface IClaimPieChart {
  number: number;
  percentage: number;
  claimantType: string;
}

export interface IClaimStatistics {
  percentageByType: IClaimPieChart[];
  averageByStage: IClaimBarCharts[];
  percentageByStage: IClaimPercentage[];
}

export interface SelectOption {
  title: string;
  formSectionId: number;
  isSelected?: boolean;
  hasError?: boolean;
  status?: string;
  id: number;
  stages: IClaimStage[];
}

export interface IWorshop {
  id: number;
  name: string;
  address: string;
  schedule: string;
  url: string;
}

export interface IWorkshopInfo {
  WorkshopId?: number;
  Name?: string;
  Email?: string;
  CompanyId?: number;
  Address?: string;
  PhoneContact?: string;
  Schedule?: string;
  Url?: string;
}

export enum ReceiveCheckType {
  POST_MAIL = 'POST_MAIL',
  PICKUP_OFFICE = 'PICKUP_OFFICE',
}

export enum AgreementTypes {
  RECEIVE_PAYMENT = 'RECEIVE_PAYMENT',
  REPAIR_CONTINUE = 'CONTINUE_REPAIR',
}

export enum Payments {
  CHECK = 'CHECK',
  ACH = 'ACH',
  ATH = 'ATH',
}

export enum ClaimantType {
  CLAIM_INSURED = 'ASEGURADO',
  CLAIM_INJURED = 'PERJUDICADO',
}

export enum FieldTypes {
  PHOTOS = 'PHOTOS',
  AUDIO = 'audio',
  TEXT = 'text',
  FILLED_TEXT = 'filledtext',
  NUMBER = 'number',
  CHECKBOX = 'checkbox',
  CHECKBOX_LIST = 'checkboxlist',
  RADIOBUTTON = 'radiobutton',
  DISCLAIMER = 'disclaimer',
  DROPDOWN = 'dropdown',
  TEXTAREA = 'textarea',
  SIGNATURE = 'signature',
  STYLIZED_RADIOBUTTONS = 'stylizedradiobuttons',
  SWITCH = 'switch',
  DATE = 'date',
  TIME = 'time',
  DIAGRAM = 'diagram',
  HTMLTEXT = 'htmltext',
  MAP = 'map',
  DAMAGES_DIAGRAM = 'damagesdiagram',
  DYNAMIC_DROPDOWN = 'dynamicdropdown',
  BUTTON = 'button',
  CHIPSTATUS = 'chipstatus',
  ESTIMATED_PAYMENT = 'ESTIMATED_PAYMENT',
  REQUEST_CLAIMS = 'REQUEST_CLAIMS',
  EXPENSES = 'EXPENSES',
  TABLE_DATA = 'TABLE_DATA',
  AGREEMENT_DETAIL = 'AGREEMENT_DETAIL',
  TOTAL_LOSS = 'TOTAL_LOSS',
  SUBROGATION = 'SUBROGATION',
  REINSPECTION = 'reinspection',
  DEPRECIATION = 'DEPRECIATION',
  THIRDS_CLAIMS = 'THIRDS_CLAIMS',
  INJURED_CLAIMS = 'INJURED_CLAIMS',
  AUTO_PARTS = 'AUTO_PARTS',
  CIRCUNSTANCES_CLAIMS = 'CIRCUNSTANCES_CLAIMS',
}

export enum StepStatus {
  PENDING_INSPECT = 'PENDING_INSPECT',
  PENDING_AUDIT = 'PENDING_AUDIT',
  PENDING_ESTIMATE = 'PENDING_ESTIMATE',
  PENDING_ADJUSTMENT = 'PENDING_ADJUSTMENT',
  PENDING_APPRO_AGREE = 'PENDING_APPRO_AGREE',
  PENDING_REPAIR = 'PENDING_REPAIR',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  IN_PROCESS = 'IN_PROCESS',
  PENDING = 'PENDING',
  FINALIZED_PAYMENT = 'FINALIZED_PAYMENT',
  FINALIZED_REPAIR = 'FINALIZED_REPAIR',
  PENDING_INSURANCE_PROCESSED = 'PENDING_INSURANCE_PROCESSED',
  PENDING_SINISTER = 'PENDING_SINISTER',
}

export const isInsurerCR = (): boolean => {
  return Boolean(
    Number(localStorage.getItem('rolId')) === Rol.INSURER &&
      localStorage.getItem('countryCode') === CountryCode.CRC,
  );
};

export enum CurrentStatusText {
  PENDING_INSPECT = 'Inspección',
  PENDING_AUDIT = 'Auditoria',
  PENDING_ESTIMATE = 'Estimado de daños',
  PENDING_ADJUSTMENT = 'Ajuste de daños',
  PENDING_APPRO_AGREE = 'Acuerdo Final',
  PENDING_REPAIR = 'Pendiente Reparación',
  PENDING_PAYMENT = 'Pendiente Pago',
  IN_PROCESS = 'En proceso',
  PENDING = 'Pendiente',
  FINALIZED_PAYMENT = 'Caso Finalizado',
  FINALIZED_REPAIR = 'Caso Finalizado',
  CANCELLED = 'Cancelado',
  DELETED = 'Eliminado',
  COMPLETE = 'Caso Finalizado',
  TERMINATED_BY_INSURER = 'Procesado Asegurador',
  FINALIZED_WITHOUT_ESTIMATED = 'Caso Finalizado',
  PENDING_APPROVAL = 'Pendiente de aprobación',
  PENDING_INSURANCE_PROCESSED = 'Pendiente Procesado Aseguradora',
  FINALIZED_INSURANCE_PROCESSED = 'Procesado Aseguradora',
  PENDING_DAMAGE_ANALYSIS = 'Pendiente Análisis',
  FINALIZED_DAMAGE_ANALYSIS = 'Finalizado Análisis',
  PENDING_MEDICAL_AUDIT = 'Pendiente Auditoría Médica',
  FINALIZED_MEDICAL_AUDIT = 'Finalizado Auditoría Médica',
  PENDING_LEGAL_CONCEPT = 'Pendiente Concepto Jurídico',
  FINALIZED_LEGAL_CONCEPT = 'Finalizado Concepto Jurídico',
  PENDING_SINISTER = 'Pendiente Siniestrar',
  FINALIZED_SINISTER = 'Finalizado Siniestrar',
  FINALIZED_AUDIT = 'Caso Finalizado',
  FINALIZED = 'Caso Finalizado',
}
